// Import Library
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { differenceInYears } from 'date-fns';

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const title = "Destacad@s";
const desc = "";
const currentDate = new Date();

const labThumbStyle = {
    position: 'relative',
    overflow: 'hidden',
}

const labContentStyle = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '50%', // Puedes ajustar esta altura
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent)',
    color: 'white',
    padding: '5px', // Espacio reducido
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end', // Asegura que los textos se mantengan en la parte inferior
}

const Top = (props) => {

    const { countryCode } = props
    const [topProfile, setTopProfile] = useState([]);

    const fetchStartValues = async () => {
        const responseTopProfileByCountry = await posterServices.getTop(countryCode);
        if (!responseTopProfileByCountry.code) {
            setTopProfile(responseTopProfileByCountry)
        }
    }

    const getProfilePhoto = (photos) => {
        const item = Array.isArray(photos) ? photos.find(e => e.isProfilePhoto) : null;
        return item
            ? `${process.env.REACT_APP_URL_MEDIA}${item.path}/${item.fileName}`
            : `${process.env.PUBLIC_URL}/assets/images/logo/fondo_xugars_2.png`;
    }

    const getStyles = (photos) => {
        const baseStyle = {
            height: '450px',
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            objectPosition: 'center',
        }
        const hasProfilePhoto = Array.isArray(photos) && photos.find(e => e.isProfilePhoto);
        return {
            ...baseStyle,
            objectFit: hasProfilePhoto ? 'cover' : 'scale-down',
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <section className="member-section padding-tb">
            <div className="container">
                <div className="section-header">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center g-3 g-md-4 row-cols-xl-5 row-cols-md-3 row-cols-1">
                        {topProfile.map((value, index) => (
                            <Link to="/profile" state={{ profile: value }} key={index}>
                                <div className="col">
                                    <div className="lab-item member-item style-1">
                                        <div className="lab-inner">
                                            <div className="lab-thumb" style={labThumbStyle}>
                                                <img
                                                    src={getProfilePhoto(value.photos)}
                                                    alt={`${value.alias}`}
                                                    style={getStyles(value.photos)}
                                                />
                                                <div className="lab-content" style={labContentStyle}>
                                                    <h6>{value.alias}</h6>
                                                    <h6>{value.nameNationality}</h6>
                                                    <h6>{differenceInYears(currentDate, value.birthDate)}</h6>
                                                    <h6>{value.geographicLocation2Name}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Top;