// Import Library
import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// Import Component
import SelectAge from "../select/select-age";
import SelectCountry from "../select/select-country";
import SelectCategory from "../select/select-category";
import SelectHeight from "../select/select-height";

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const btnText = "Buscar";

const AboutTwo = (props) => {

    const navigate = useNavigate();
    const { countryCode } = props;
    const [loadingFilter, setLoadingFilter] = useState(null);
    const [searchFilter, setSearchFilter] = useState({ nationality: null, category: null, height: null, age: null });

    const handleSelectChange = (field, value) => {
        setSearchFilter(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleButtonClick = (e) => {
        setSearchFilter(prevState => ({
            ...prevState,
            [field]: value
        }));
        e.preventDefault();
        navigate('/community', { state: { loadingFilter, searchFilter } });
    }

    const fetchStartValues = async () => {
        const responseSearchFilter = await posterServices.getLoadingFilter(countryCode);        
        if (!responseSearchFilter.code && responseSearchFilter.catalogNationality.length > 0 &&
                                          responseSearchFilter.catalogCategory.length > 0 &&
                                          responseSearchFilter.catalogHeight.length > 0 &&
                                          responseSearchFilter.ages.length > 0) {
            setLoadingFilter(responseSearchFilter)
            setSearchFilter({
                nationality: responseSearchFilter.catalogNationality[0].code,
                category: responseSearchFilter.catalogCategory[0].code,
                height: responseSearchFilter.catalogHeight[0].code,
                age: responseSearchFilter.ages[0].age
            });
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <Fragment>
            {loadingFilter &&
                Array.isArray(loadingFilter.ages) && loadingFilter.ages.length > 0 &&
                Array.isArray(loadingFilter.catalogCategory) && loadingFilter.catalogCategory.length > 0 &&
                Array.isArray(loadingFilter.catalogHeight) && loadingFilter.catalogHeight.length > 0 &&
                Array.isArray(loadingFilter.catalogNationality) && loadingFilter.catalogNationality.length > 0 &&
                countryCode && (
                    <div className="about about--style3 padding-top pt-xl-0">
                        <div className="container">
                            <div className="section__wrapper wow fadeInUp" data-wow-duration="1.5s">
                                <form onSubmit={handleButtonClick}>
                                    <div className="banner__list">
                                        <div className="row g-4 align-items-center row-cols-xl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                                            <div className="col">
                                                <label>Nacionalidad</label>
                                                <div className="banner__inputlist">
                                                    <SelectCountry
                                                        loadingDefault={loadingFilter.catalogNationality[0].code}
                                                        catalogNationality={loadingFilter.catalogNationality}
                                                        onChange={(value) => handleSelectChange('nationality', value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label>Categoria</label>
                                                <div className="banner__inputlist">
                                                    <SelectCategory select={loadingFilter.catalogCategory[0].description} catalogCategory={loadingFilter.catalogCategory} />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label>Estatura</label>
                                                <div className="banner__inputlist">
                                                    <SelectHeight select={loadingFilter.catalogHeight[0].description} catalogHeight={loadingFilter.catalogHeight} />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <label>Edad</label>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="banner__inputlist">
                                                            <SelectAge select={loadingFilter.ages[0].age} ages={loadingFilter.ages} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="banner__inputlist">
                                                            <SelectAge select={loadingFilter.ages[0].age} ages={loadingFilter.ages} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <button type="submit" className="lab-btn d-block"><span>{btnText}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
        </Fragment>
    )
}

export default AboutTwo;