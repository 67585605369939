const title = "¿Cómo publicar tu anuncio?";
const desc = "Comienza belbom en 3 simples pasos";

const AboutThreeContnetList = [
    {
        imgUrl: 'assets/images/about/01.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Crear un perfil',
        desc: 'Personaliza tu información, agrega fotos, ubicación y preferencias.',
    },
    {
        imgUrl: 'assets/images/about/02.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Publica tu anuncio',
        desc: 'Elige la forma de publicidad que mejor se adapte a tus necesidades y objetivos.',
    },
    {
        imgUrl: 'assets/images/about/03.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Recibe contactos',
        desc: 'Tu perfil cautivador atraerá visualizaciones irresistibles de tus seguidores.',
    },
];

const AboutThree = () => {

    return (
        <div className="about padding-tb">
            <div className="container">
                <div className="section-header wow fadeInUp" data-wow-duration="1.5s">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>
                <div className="section__wrapper">
                    <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
                        {AboutThreeContnetList.map((val, i) => (
                            <div className="col wow fadeInUp" data-wow-duration="1.5s" key={i}>
                                <div className="about__item text-center">
                                    <div className="about__inner">
                                        <div className="about__thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="about__content">
                                            <h4>{val.title}</h4>
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutThree;