const config = {
    url: {
        whatsapp: "https://wa.me"
    },
    googleMaps: {
        places: ['places'],
        containerStyle: {
            height: "435px",
            width: "100%"
        },
        center: {
            lat: -0.1779392,
            lng: -78.4813157,
        },
        options: {
            disableDefaultUI: true,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
        }
    },
};

export default config;