// Import Library
import { Fragment, useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { differenceInYears } from 'date-fns';

// import context
import { Context as UserContext } from '../dataStore/userAccessContext';

// Import Component
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../component/layout/pagination";
import Loading from '../component/loading/loading';

// Import Web Api
import posterServices from '../dataservices/posterServices'

// Import Animation
import animation from "../assets/animation.json";

// Import Config
import config from "../config";

const currentDate = new Date();

const CommunityPage = () => {

    const { state } = useContext(UserContext);
    const { geolocation } = state;

    const location = useLocation();
    const { loadingFilter, searchFilter } = location.state || {};
    const [profileFound, setProfileFound] = useState(null);

    const fetchStartValues = async () => {
        const getSearchFilter = await posterServices.getSearchFilter(searchFilter.nationality, searchFilter.category, searchFilter.height, searchFilter.age, searchFilter.age,);
        if (!getSearchFilter.code) {
            setProfileFound(getSearchFilter)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])    
    
    return (
        <Fragment>
            {geolocation.loading && loadingFilter && !profileFound ? (
                <Loading animation={animation} />
            ) : (
                <Fragment>
                    <HeaderTwo country={geolocation.data.country} />
                    <PageHeader title={'Community Page'} curPage={'Community Page'} />
                    {profileFound && profileFound.data.length > 0 && (
                        <section className="story-section padding-tb">
                            <div className=" container">
                                <div className="section-wrapper">
                                    <div className="row justify-content-center g-4 row-cols-xl-3 row-cols-sm-2 row-cols-1">
                                        {profileFound.data.map((value, index) => (
                                            <div className="col" key={index}>
                                                <div className="story-item lab-item">
                                                    <div className="lab-inner">
                                                        <div className="lab-thumb">
                                                            <Link
                                                                to="/profile"
                                                                state={{ profile: value, loadingFilter: loadingFilter }}
                                                                key={index}
                                                            >
                                                                <img src={`${process.env.REACT_APP_URL_MEDIA}${value.photos[0].path}/${value.photos[0].fileName}`} alt={`${value.alias}`} />
                                                            </Link>
                                                        </div>
                                                        <div className="lab-content">
                                                            <h4>{value.alias}</h4>
                                                            <div className="lab-content-author">
                                                                <div className="left">
                                                                    <Link to={`${config.url.whatsapp}/${value.phoneNumber}`} target="_blank" rel="noopener noreferrer"><i className="icofont-brand-whatsapp" style={{ fontSize: '50px' }}></i></Link>
                                                                </div>
                                                                <div className="right">
                                                                    <span>Pais: {value.nameCountry}</span>
                                                                    <span>Edad: {differenceInYears(currentDate, value.birthDate)}</span>
                                                                    <span>Ciudad: {value.geographicLocation2Name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <Pagination />
                                </div>
                            </div>
                        </section>
                    )}
                    <Footer />
                </Fragment>
            )}
        </Fragment >
    );
}

export default CommunityPage;