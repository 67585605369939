import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import CommunityPage from "./page/community";
import ContactPage from "./page/contact";
import ActiveGroup from "./page/active-group";
import GroupSingle from "./page/group-single";
import HomeTwo from "./page/hometwo";
import MemberPage from "./page/member";
import Policy from "./page/policy";
import ProfilePage from "./page/profile";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<HomeTwo />} />
				<Route path="community" element={<CommunityPage />} />
				<Route path="members" element={<MemberPage />} />
				<Route path="profile" element={<ProfilePage />} />
				<Route path="active-group" element={<ActiveGroup />} />
				<Route path="group-single" element={<GroupSingle />} />
				<Route path="policy" element={<Policy />} />
				<Route path="*" element={<ErrorPage />} />
				<Route path="contact" element={<ContactPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;