// Import Library
import { useEffect, useState, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { differenceInYears } from 'date-fns';
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Component
import Loading from '../component/loading/loading';
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../component/layout/pagination";

// Import Web Api
import posterServices from '../dataservices/posterServices'

// Import Animation
import animation from "../assets/animation.json";

// Import Config
import config from "../config";

const currentDate = new Date();

const cardStyle = {
    height: '100%',
    cursor: 'pointer',
}

const labInnerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '100%'
}

const labThumbStyle = {
    flex: '0 0 15%',
    minWidth: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
}

const swiperStyle = {
    height: '100%'
}

const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block'
}

const blockStyle = {
    display: 'block'
}

const flexCenterStyle = {
    display: 'flex',
    alignItems: 'center'
}

const iconStyle = {
    marginRight: '5px'
}

const ActiveGroup = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { geographicLocation2Code } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [profilesByCity, setProfilesByCity] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(2);
    const [totalPages, setTotalPages] = useState(0);

    const fetchStartValues = async () => {
        setLoading(true)
        const response = await posterServices.getCity(geographicLocation2Code, pageNumber, pageSize);
        setLoading(false)
        if (response && response.succeeded) {
            setProfilesByCity(response.data)
            setPageNumber(response.pageNumber)
            setPageSize(response.pageSize)
            setTotalPages(response.totalPages)
        }
    }

    const getProfilePhoto = (photo) => {
        if (photo && photo.fileName && photo.path) {
            return `${process.env.REACT_APP_URL_MEDIA}${photo.path}/${photo.fileName}`;
        }
        return `${process.env.PUBLIC_URL}/assets/images/logo/fondo_xugars_2.png`;
    }

    const handleCardClick = () => {
        navigate('/group-single');
    }

    useEffect(() => {
        fetchStartValues()
    }, [pageNumber])

    return (
        <Fragment>
            {loading && !profilesByCity ? (
                <Loading animation={animation} />
            ) : (
                <Fragment>
                    <HeaderTwo />
                    <PageHeader title={'All Groups'} curPage={'Groups'} />
                    <section className="group-page-section group-section style2 padding-tb">
                        <div className="container">
                            <div className="groups-wrapper">
                                <div className="row g-4 row-cols-1">
                                    {profilesByCity.map((value, index) => (
                                        <div className="col" key={index}>
                                            <div className="group-item lab-item" style={cardStyle} onClick={() => handleCardClick()}>
                                                <div className="lab-inner" style={labInnerStyle}>
                                                    <div className="lab-thumb" style={labThumbStyle}>
                                                        <Swiper
                                                            loop={'true'}
                                                            autoplay={{
                                                                delay: 5000,
                                                                disableOnInteraction: false,
                                                            }}
                                                            navigation={{
                                                                prevEl: '.pro-single-prev',
                                                                nextEl: '.pro-single-next',
                                                            }}
                                                            modules={[Autoplay, Navigation]}
                                                            style={swiperStyle}
                                                        >
                                                            {value.photos.map((photo, index) => (
                                                                <SwiperSlide key={index}>
                                                                    <img
                                                                        src={getProfilePhoto(photo)}
                                                                        alt={`${photo.fileName}`}
                                                                        style={imgStyle}
                                                                    />
                                                                </SwiperSlide>
                                                            ))}
                                                        </Swiper>
                                                    </div>
                                                    <div className="lab-content">
                                                        <h3>{value.alias}</h3>
                                                        <span style={blockStyle}>{value.presentation}</span>
                                                        <span style={blockStyle}><i className="icofont-user-alt-6" style={iconStyle}></i>{`${differenceInYears(currentDate, value.birthDate)} años`}</span>
                                                        <span style={blockStyle}><i className="icofont-location-pin" style={iconStyle}></i>{value.geographicLocation2Name}</span>
                                                        <span style={blockStyle}><i className="icofont-earth" style={iconStyle}></i>{value.nameNationality}</span>
                                                        <ul className="lab-content-icon">
                                                            <li
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    window.open(`${config.url.whatsapp}/${value.phoneNumber}`, '_blank')
                                                                }}
                                                            >
                                                                <i className={"icofont-brand-whatsapp"}></i>
                                                            </li>
                                                            <li>
                                                                <i className={"icofont-phone"}></i>
                                                            </li>
                                                            <span style={flexCenterStyle}>
                                                                {`${value.phoneNumber}`}
                                                            </span>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Pagination
                                    setPageNumber={setPageNumber}
                                    setPageSize={setPageSize}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                    totalPages={totalPages}
                                />
                            </div>
                        </div>
                    </section>
                    <style>
                        {`
                            @media (max-width: 768px) {
                                .lab-thumb {
                                    flex: 0 0 40% !important;
                                    minWidth: 120px !important;
                                }

                                .lab-content {
                                    flex: 0 0 60% !important;
                                    padding-left: 10px !important; 
                                }
                            }
                        `}
                    </style>
                    <Footer />
                </Fragment>
            )}
        </Fragment>
    );
}

export default ActiveGroup