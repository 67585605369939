import React, { useState } from 'react';

const SelectCountry = (props) => {

    const { loadingDefault, catalogNationality, onChange } = props;
    const [selectedValue, setSelectedValue] = useState(loadingDefault);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        onChange(event.target.value)
    };

    return (
        <select value={selectedValue} onChange={handleChange}>
            {catalogNationality.map((value, index) => (
                <option key={index} value={value.code}>
                    {value.description}
                </option>
            ))}
        </select>
    );
}

export default SelectCountry;