// Import Library
import { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

// import context
import { Context as UserContext } from '../../dataStore/userAccessContext'

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const title = "Te podría gustar";

const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
}

const LikeMember = () => {

    const { state } = useContext(UserContext);
    const { geolocation } = state;
    const location = useLocation();
    const { loadingFilter } = location.state || {};
    const [topProfile, setTopProfile] = useState([]);

    const fetchStartValues = async () => {
        const responseTopProfileByCountry = await posterServices.getTop(geolocation.data.country_code3);
        if (!responseTopProfileByCountry.code) {
            setTopProfile(responseTopProfileByCountry)
        }
    }

    const getProfilePhoto = (photos) => {
        const item = Array.isArray(photos) ? photos.find(e => e.isProfilePhoto) : null;
        return item
            ? `${process.env.REACT_APP_URL_MEDIA}${item.path}/${item.fileName}`
            : `${process.env.PUBLIC_URL}/assets/images/logo/fondo_xugars_2.png`;
    }

    const getImageThumbStyle = (photos) => {
        const baseStyle = {
            width: '108px',
            height: '108px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center'
        }
        const hasProfilePhoto = Array.isArray(photos) && photos.some(e => e.isProfilePhoto);
        return {
            ...baseStyle,
            alignItems: hasProfilePhoto ? 'center' : 'normal',
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <div className="widget like-member">
            <div className="widget-inner">
                <div className="widget-title">
                    <h5>{title}</h5>
                </div>
                <div className="widget-content">
                    <div className="row row-cols-3 row-cols-sm-auto g-3">
                        {topProfile.map((value, index) => (
                            <div className="col" key={index}>
                                <div className="image-thumb" style={getImageThumbStyle(value.photos)}>
                                    <Link to="/profile" state={{ profile: value, loadingFilter: loadingFilter }}>
                                        <img
                                            src={getProfilePhoto(value.photos)}
                                            alt={`${value.alias}`}
                                            style={imgStyle}
                                        />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LikeMember;