const SelectHeight = (props) => {

    const { select, catalogHeight } = props;

    return (
        <select defaultValue={select}>
            {catalogHeight.map((height, index) => (
                <option key={index} value={height.code}>
                    {height.description}
                </option>
            ))}
        </select>
    );
}

export default SelectHeight;