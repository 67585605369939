const SelectAge = (props) => {

    const { select, ages } = props;

    return (
        <select defaultValue={select}>
            {ages.map((age, index) => (
                <option key={index} value={age.age}>
                    {age.age}
                </option>
            ))}
        </select>
    );
}

export default SelectAge;