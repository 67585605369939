// Import Library
import { Fragment, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// import context
import { Context as UserContext } from '../../dataStore/userAccessContext'

// Import Component
import SelectAge from "../select/select-age";
import SelectCountry from "../select/select-country";
import SelectCategory from "../select/select-category";
import SelectHeight from "../select/select-height";

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const title = "Filtrar Miembro Búsqueda";
const desc = "Tu cita perfecta está a solo un clic.";
const btnText = "Buscar";

const FilterSearch = () => {

    const { state } = useContext(UserContext);
    const { geolocation } = state;
    const navigate = useNavigate();
    const [loadingFilter, setLoadingFilter] = useState(null);

    const handleButtonClick = (e) => {
        e.preventDefault();
        navigate('/community', { state: { loadingFilter } });
    }

    const fetchStartValues = async () => {
        const responseSearchFilter = await posterServices.getLoadingFilter(geolocation.data.country_code3);
        if (!responseSearchFilter.code) {
            setLoadingFilter(responseSearchFilter)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <Fragment>
            {loadingFilter &&
                Array.isArray(loadingFilter.ages) && loadingFilter.ages.length > 0 &&
                Array.isArray(loadingFilter.catalogCategory) && loadingFilter.catalogCategory.length > 0 &&
                Array.isArray(loadingFilter.catalogHeight) && loadingFilter.catalogHeight.length > 0 &&
                Array.isArray(loadingFilter.catalogNationality) && loadingFilter.catalogNationality.length > 0 &&
                (
                    <div className="widget search-widget">
                        <div className="widget-inner">
                            <div className="widget-title">
                                <h5>{title}</h5>
                            </div>
                            <div className="widget-content">
                                <p>{desc}</p>
                                <form onSubmit={handleButtonClick} className="banner-form">
                                    <div className="gender">
                                        <div className="custom-select right w-100">
                                            <SelectCountry select={loadingFilter.catalogNationality[0].description} catalogNationality={loadingFilter.catalogNationality} />
                                        </div>
                                    </div>
                                    <div className="person">
                                        <div className="custom-select right w-100">
                                            <SelectCategory select={loadingFilter.catalogCategory[0].description} catalogCategory={loadingFilter.catalogCategory} />
                                        </div>
                                    </div>
                                    <div className="city">
                                        <div className="custom-select right w-100">
                                            <SelectHeight select={loadingFilter.catalogHeight[0].description} catalogHeight={loadingFilter.catalogHeight} />
                                        </div>
                                    </div>
                                    <div className="age">
                                        <div
                                            className="right d-flex justify-content-between w-100">
                                            <div className="custom-select">
                                                <SelectAge select={loadingFilter.ages[0].age} ages={loadingFilter.ages} />
                                            </div>
                                            <div className="custom-select">
                                                <SelectAge select={loadingFilter.ages[0].age} ages={loadingFilter.ages} />
                                            </div>
                                        </div>
                                    </div>
                                    <button>{btnText}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
        </Fragment>
    );
}

export default FilterSearch;