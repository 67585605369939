// Import Context
import createAppContext from "./createAppContext";

// Import Utils
import aType from "../utils/ActionTypes";

// Import Reducer
import userReducer from "./reducer";

// Import Web Api
import externalServices from "../dataservices/externalServices";

// User Actions
const setGeolocation = (dispatch) => async () => {
    try {
        const response = await externalServices.getGeolocation();
        dispatch({ type: aType.SET_GEOLOCATION, payload: { data: response, loading: false } });
    } catch (error) {
        dispatch({ type: aType.ERROR, payload: { loading: false, error: error } });
    }
}

// Export Methods with Create Context
export const { Provider, Context } = createAppContext(
    userReducer,
    { setGeolocation, },
    { geolocation: { data: null, loading: true, error: null } }
);