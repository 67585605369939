import http from "../comunication/http-common";

const getGeolocation = async () => {
    const url = process.env.REACT_APP_GEOLOCATION_URL;
    return await http.get(`${url}`)
        .then((result) => result.data)
        .catch((error) => { return { "code": error, "message": error } });
};

const getAddressGoogle = async (latitude, longitude) => {
    const url = process.env.REACT_APP_URL_GOOGLE_MAPS;
    return await http.get(url, {
        params: {
            latlng: `${latitude.toString()},${longitude.toString()}`,
            key: process.env.REACT_APP_KEY_GOOGLE_MAPS
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error, "message": error }
        });
};

export default { getGeolocation, getAddressGoogle }