const SelectCategory = (props) => {

    const { select, catalogCategory } = props;

    return (
        <select defaultValue={select}>
            {catalogCategory.map((category, index) => (
                <option key={index} value={category.code}>
                    {category.description}
                </option>
            ))}
        </select>
    );
}

export default SelectCategory;