// Import Library
import React from "react";
import Lottie from "lottie-react";

const Loading = (props) => {

    const { animation } = props

    return (
        <div style={{
            //backgroundColor: "#e953bb",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Lottie animationData={animation} loop={true} autoplay={true} />
        </div>
    )
}

export default Loading;