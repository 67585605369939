// Import Library
import { useEffect, useState } from "react";

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const comTitle = "Red Belbom";
const aboutTitle = "Acerca Belbom";
const aboutDesc = "Portal de anuncios clasificados para Belbom";
const featureTitle = "Condiciones de Uso y Responsabilidad";
const featureDesc = `Belbom no interviene en las relaciones entre usuarios y anunciantes.
Al usar nuestro sitio web, el usuario acepta nuestros términos y condiciones y se compromete a estar al tanto de posibles cambios.
Los anuncios son responsabilidad exclusiva del anunciante. Belbom no verifica ni se hace responsable por su veracidad, legalidad o cualquier infracción que puedan implicar.
Belbom no actúa como intermediario ni gestiona la interacción entre los usuarios.`;
const pageTitle = "Contactos y Soporte";

let ContactList = [
    {
        Name: 'Correo',
        Details: 'contact@belbom.com',
    },
]

const imageContainerStyle = {
    display: 'inline-block',
    width: '50px',
    height: '50px',
    overflow: 'hidden',
    cursor: 'pointer',
}

const flagStyle = (country) => ({
    backgroundImage: `url(${country.flagUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const Footer = () => {

    const [publisherCountries, setPublisherCountries] = useState([]);

    const fetchStartValues = async () => {
        const responsePublisherCountries = await posterServices.getCountries();
        if (!responsePublisherCountries.code) {
            setPublisherCountries(responsePublisherCountries)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <footer className="footer footer--style2">
            <div className="footer__top bgimg" style={{ backgroundImage: "url(/assets/images/bg-img/footer.jpg)" }}>
                <div className="footer__newsletter wow fadeInUp" data-wow-duration="1.5s">
                    <div className="container">
                        <div className="row g-4 justify-content-center">
                            <div className="col-lg-6 col-12">
                                <div className="footer__newsletter--area justify-content-xxl-center">
                                    <div className="footer__newsletter--title me-xl-4">
                                        <h4>{comTitle}</h4>
                                    </div>
                                    <div className="footer__newsletter--social">
                                        <ul>
                                            {publisherCountries.map((country, index) => (
                                                <li key={index}>
                                                    <a
                                                        onClick={() => console.log("hola")}
                                                        style={imageContainerStyle}
                                                        title={country.nameSpanish}
                                                    >
                                                        <i style={flagStyle(country)} />
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__toparea padding-tb wow fadeInUp" data-wow-duration="1.5s">
                    <div className="container">
                        <div className="row g-5 g-xl-4">
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="footer__item footer--about">
                                    <div className="footer__inner">
                                        <div className="footer__content">
                                            <div className="footer__content--title">
                                                <h4>{aboutTitle}</h4>
                                            </div>
                                            <div className="footer__content--desc">
                                                <p>{aboutDesc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6 col-12">
                                <div className="footer__item footer--about">
                                    <div className="footer__inner">
                                        <div className="footer__content">
                                            <div className="footer__content--title">
                                                <h4>{featureTitle}</h4>
                                            </div>
                                            <div className="footer__content--desc">
                                                {featureDesc.split('\n').map((line, index) => (
                                                    <p key={index}>{line}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="footer__item footer--support">
                                    <div className="footer__inner">
                                        <div className="footer__content">
                                            <div className="footer__content--title">
                                                <h4>{pageTitle}</h4>
                                            </div>
                                            <div className="footer__content--desc">
                                                {ContactList.map((val, i) => (
                                                    <p key={i}><b>{val.Name} :</b> {val.Details}</p>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__bottom wow fadeInUp" data-wow-duration="1.5s">
                <div className="container">
                    <div className="footer__content text-center">
                        <p className="mb-0">{`Copyright © ${new Date().getFullYear()} Powered by Belbom All Rights Reserved`}</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer