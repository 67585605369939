const Pagination = (props) => {
    
    const { setPageNumber, pageNumber, totalPages } = props;

    const goToNextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(prevPage => prevPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(prevPage => prevPage - 1);
        }
    };

    const getPageNumbers = () => {
        let pages = [];
        if (totalPages === 1) {
            // Solo una página
            pages = [1];
        } else if (pageNumber === 1) {
            // Primera página
            pages = [1];
        } else if (pageNumber === 2) {
            // Segunda página
            pages = [1, 2];
        } else if (pageNumber > 2 && pageNumber < totalPages) {
            // Páginas intermedias
            pages = [1, '...', pageNumber];
        } else if (pageNumber === totalPages) {
            // Última página
            pages = [1, '...', totalPages];
        }
        return pages;
    }

    const getStyle = (page) => {

        if (totalPages <= 2 || (page === 1 && ![1, '...'].includes(pageNumber))) {
            return {
                cursor: 'pointer',
            };
        }

        return {
            cursor: page === pageNumber ? 'default' : 'pointer',
            background: page === pageNumber ? '#ffff' : 'transparent',
            color: page === pageNumber ? 'red' : 'black',
            fontWeight: page === pageNumber ? 'bold' : 'normal'
        }
    }


    return (
        <div className="paginations">
            <ul className="lab-ul d-flex flex-wrap justify-content-center mb-1">

                {/* Botón "Anterior" */}
                {pageNumber > 1 && (
                    <li>
                        <a onClick={goToPreviousPage} style={{ cursor: 'pointer' }}>
                            <i className="icofont-rounded-double-left"></i>
                        </a>
                    </li>
                )}

                {/* Renderización de los números de página */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="d-none d-sm-block">
                        {page === '...' ? (
                            <span>...</span>
                        ) : (
                            <a onClick={() => page !== pageNumber && setPageNumber(page)} style={getStyle(page)}>
                                {page}
                            </a>
                        )}
                    </li>
                ))}

                {/* Botón "Siguiente" */}
                {pageNumber < totalPages && (
                    <li>
                        <a onClick={goToNextPage} style={{ cursor: 'pointer' }}>
                            <i className="icofont-rounded-double-right"></i>
                        </a>
                    </li>
                )}

            </ul>
        </div>
    )
}

export default Pagination